<template>
    <b-navbar ref="navbar" :fixed-top="true" :centered="true" :transparent="true">
        <template #brand>
            <div :style="navbarMarginLeftStyle" />
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img src="@/assets/airplane.png" :alt="$CONSTANTS.PAGE_NAME"
                    :style="`filter: invert(${$store.state.darkMode ? 1 : 0});`" />
            </b-navbar-item>
        </template>
        <template #start>
            <!--b-navbar-item tag="router-link" :to="{ path: '/' }">{{ $t("home") }}</b-navbar-item-->
            <b-navbar-item tag="router-link" :to="{ path: 'demo' }">FlyBiddr {{ $t("demo") }}</b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item>
                <span class="icon-text custom-hover-primary" v-on:click="openLanguageSelectionModal">
                    <span class="icon">
                        <i>
                            <b-icon icon="globe" class="is-clickable" />
                        </i>
                    </span>
                    <span>{{ $i18n.locale.split("-")[0].toUpperCase() }}</span>
                </span>
            </b-navbar-item>
            <div :style="navbarMarginRightStyle" />
        </template>
    </b-navbar>
</template>

<script>
import LanguageSelectionModal from '@/components/LanguageSelectionModal.vue';

export default {
    computed: {
        darkMode: {
            get() {
                return this.$store.state.darkMode;
            },
            set(darkMode) {
                this.$store.state.darkMode = darkMode;
            }
        },

        isTablet() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.desktop;
        },

        navbarMarginLeftStyle() {
            const margin = Math.max((this.$store.state.windowWidth - this.$store.state.BREAKPOINTS.fullhd) * 0.5, 0);
            return `margin-left: ${margin}px;`
        },

        navbarMarginRightStyle() {
            const margin = Math.max((this.$store.state.windowWidth - this.$store.state.BREAKPOINTS.fullhd) * 0.5, 0);
            return `margin-right: ${margin}px;`
        }
    },

    methods: {
        openLanguageSelectionModal() {
            this.$buefy.modal.open({
                parent: this,
                component: LanguageSelectionModal,
                hasModalCard: false,
                trapFocus: true,
                fullScreen: false
            })
        }
    }
}
</script>

<style scoped>
.field.routerItemWithPin .field:not(:last-child) {
    margin-right: 0.4rem;
}

.vleftdivider {
    border-left: 1.5px solid #5f5f5f;
    height: 100%;
}
</style>