<template>
    <div id="app">
        <div>
            <navbar id="nav" v-on:setDarkMode="setDarkMode" />
            <router-view id="router" />
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
    components: {
        navbar: NavBar
    },
    computed: {
        darkMode: {
            get() {
                return this.$store.state.darkMode;
            },
            set(darkMode) {
                this.$store.state.darkMode = darkMode;
            }
        }
    },
    mounted() {
        document.title = this.$CONSTANTS.PAGE_NAME;
        this.darkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
        this.setDarkMode();
        this.$nextTick(() => {
            this.onResize();
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.$store.state.windowWidth = window.innerWidth;
            this.$store.state.windowHeight = window.innerHeight;
        },

        async setDarkMode() {
            const id = "dynamicDarkModeCss";
            const el = document.getElementById(id);
            if (this.darkMode && el)
                return;
            if (this.darkMode) {
                const file = document.createElement("link");
                file.id = id;
                file.rel = "stylesheet";
                file.href = "/css/style_dark.css";
                document.head.appendChild(file);
            } else {
                if (el)
                    el.remove();
            }
        }
    }
}
</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('@/assets/generated/style.css') screen;
/*@import url('@/assets/generated/style_dark.css') screen and (prefers-color-scheme: dark);*/

/*@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200&display=swap');
font-family: 'Plus Jakarta Sans', sans-serif;*/

#app {
    font-family: DM Sans, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#nav a {
    font-weight: bold;
}

#nav a.router-link-exact-active {
    color: #1c79e4;
}

/* hide scrollbar */
element {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
}

::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.underline-on-hover:hover {
    text-decoration: underline;
}

.responsive-text.xxx-large {
    font-size: min(max(10vw, 40px), 80px);
}

.responsive-text.xx-large {
    font-size: min(max(9vw, 36px), 72px);
}

.responsive-text.x-large {
    font-size: min(max(8vw, 32px), 64px);
}

.responsive-text.large {
    font-size: min(max(7vw, 28px), 56px);
}

.responsive-text.medium {
    font-size: min(max(6vw, 24px), 48px);
}

.responsive-text.small {
    font-size: min(max(5vw, 20px), 40px);
}

.responsive-text.x-small {
    font-size: min(max(4vw, 16px), 32px);
}

.responsive-text.xx-small {
    font-size: min(max(3vw, 12px), 24px);
}

.responsive-text.xxx-small {
    font-size: min(max(2vw, 8px), 16px);
}
</style>
